@import '/src/styles/common.scss';

.sub-title {
    color     : $normal-color;
    background: none;

    &:hover {
        background: none;

        svg {
            opacity: 1;
        }
    }

    &:visited {
        color: $normal-color;
    }

    svg {
        opacity : 0;
        position: absolute;
        height  : 100%;
        top     : -3px;
        left    : -$normal-font-size;
    }
}

.content {
    font-family: Neucha, sans-serif;

    h4 {
        position: relative;

        .link {
            margin-left: $normal-size;
            font-size  : $normal-font-size;
        }
    }
}