@import '/src/styles/common.scss';

html {
    position: relative;
}

html,
body,
#root {
    min-height: 100vh;
}

body {
    margin          : 0;
    background-image: url('../assets/bg.png');
}

a {
    background: none;
    outline   : none;
    cursor    : pointer;

    &:visited {
        text-decoration: none;
        color          : $link-color;
    }

    &:hover {
        @include underline;
    }
}

p {
    line-height: $normal-line-height;
}

ul li {
    line-height: $normal-line-height;
}

button {

    &:focus,
    &:visited {
        color       : $normal-color;
        border-color: $normal-color;
    }
}