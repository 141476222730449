@import '/src/styles/common.scss';

.site {
    max-width: 1440px;
}

.title {
    text-align: center;
}

.active {
    color: $hover-color !important
}

.main {
    position: relative;

    .top-btn-wrapper {
        position : sticky;
        bottom   : 46px;
        float    : right;
        transform: translateX(100px);
    }
}   

.record {
    position  : absolute;
    text-align: center;
    bottom    : 0;
    width     : 100%;

    span {
        font-size: $small-font-size;
        color    : #a8a8a8;
        letter-spacing: 1px;
        cursor   : pointer;
    }
}