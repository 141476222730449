/* size */
$normal-size       : 8px;
$large-size        : 16px;
$normal-line-height: 1.6;

$small-font-size  : 16px;
$normal-font-size : 20px;
$large-font-size  : 24px;

/* color */
$hover-color  :#868e96;
$link-color   : #0071de;
$normal-color : #41403e;
$light-color  : #e6e7e9;

/* decoration */
@mixin underline {
    background-position: 0 90%;
    background-repeat  : repeat-x;
    background-size    : 4px 3px;
    background-image   : linear-gradient(5deg, transparent 65%, #0071de 80%, transparent 90%), linear-gradient(165deg, transparent 5%, #0071de 15%, transparent 25%), linear-gradient(165deg, transparent 45%, #0071de 55%, transparent 65%), linear-gradient(15deg, transparent 25%, #0071de 35%, transparent 50%);
}