@import '/src/styles/common.scss';

h4 {
    margin-bottom : $normal-size;
    padding-bottom: $normal-size;
    border-bottom : 1px solid $light-color;
}

.sub-title {
    color     : $normal-color;
    background: none;

    &:hover {
        background: none;

        svg {
            opacity: 1;
        }
    }

    &:visited {
        color: $normal-color;
    }

    svg {
        opacity     : 0;
        margin-right: $normal-size;
    }
}

.content {
    font-family: Neucha, sans-serif;

    ul li::before {
        content: none;
    }

    ul li {
        list-style-type    : disc;
        margin-block-start : 1em;
        margin-block-end   : 1em;
        margin-inline-start: 0px;
        margin-inline-end  : 0px;

        code {
            color      : $normal-color;
            text-indent: 0;
        }

        ul li {
            list-style        : none;
            margin-block-start: 0;
            margin-block-end  : 0;

            &::before {
                content: '-';
            }
        }
    }
}